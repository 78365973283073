<template>
    <!-- Page: pages/my-notes -->

    <br/>

    <breadcrumbs :items="path"/>
    <br>

    <div class="container dfw">
        <h1>My Notes</h1>
        <user-tools/>
    </div>

    <hr/>

    <div class="general-container note-create-block">
        <div class="general-container__content">
            <div class="level-left">
                <div class="level-item">
                    <button class="button button--plus" @click.prevent="showCreate = !showCreate">
                        <img src="@/assets/svg/plus--white.svg" alt="plus icon" v-if="!showCreate"/>
                        <img src="@/assets/svg/minus--white.svg" alt="minus icon" v-else/>
                        {{ showCreate ? 'Hide' : 'Create' }} New Note
                    </button>
                </div>
            </div>
            <note-create v-if="showCreate" @created="newNoteCreated"></note-create>
        </div>
    </div>

    <notes-list :uniqueId="uniqueId" ref="notesListRef" :show-filters="true"></notes-list>
    <!-- End Page: pages/my-notes -->
</template>

<script>
    import Breadcrumbs from "./breadcrumbs.vue";
    import UserTools from "@/alivue/components/user-tools";
    import NoteCreate from "./note-create";
    import NotesList from "./notes-list";
    import { computed, ref } from "vue";
    import { useStore } from "vuex";
    import SearchBlock from "./search-block.vue";
    import { v4 as uuidv4 } from "uuid";
    
    export default {
        components: {
            NotesList,
            NoteCreate,
            Breadcrumbs,
            UserTools,
            SearchBlock,
        },
        setup() {
            const store = useStore();
            const showSearch = computed(() => store.getters.showSearch);

            const notesListRef = ref(null);
            const showCreate = ref(false);

            const newNoteCreated = () => {
                if (notesListRef.value && notesListRef.value.paginate) {
                    notesListRef.value.paginate(1);
                }
                showCreate.value = false;
            };

            // Generate a unique ID for each instance
            const uniqueId = computed(() => {
                return uuidv4();
            });

            return {
                showCreate,
                newNoteCreated,
                notesListRef,
                path: [{title: 'My Notes'}],
                showSearch,
                uniqueId,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .note-create-block {
        background-color: $white;
        border-radius: $border-radius;
        padding: 15px;
        box-shadow: $box-shadow--light;
        .label {
            color: $blue--dark;
        }
    }

    .notes-filter span {
        opacity: 0.7;
        &.active {
            opacity: 1;
        }
    }

    .button.button--plus {
        font-weight: bold;
        font-family: $header;

        &:focus, &:active, &:hover {
            color: $white;
        }

        img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
        }
    }
</style>
